import crudMutations from "../../templates/crud/mutations";
import { isNil } from "lodash";

export default {
  ...crudMutations,
  openAnnotationDialog(state, isOpen) {
    state.isAnnotationDialogOpen = isOpen;
  },
  setAnnotationDialogId(state, id) {
    state.annotationDialogId = id;
  },
  setRemark(state, remark) {
    state.model.remark = remark;
  },
  setTags(state, tags) {
    state.model.tags = tags;
  },
  addTags(state, tags) {
    state.model.tags.push(tags);
  },
  addTagsToState(state, tags) {
    tags.forEach(e => {
      if (isNil(state.tag[e.id])) {
        const key = e.id;
        state.tag[key] = e;
      }
    });
  },
  setResearchLink(state, researchLink) {
    state.model.researchLink = researchLink;
  },
  setResearchLinkEditable(state, researchLinkEditable) {
    state.model.researchLinkEditable = researchLinkEditable;
  },
  setIsSearchable(state, isSearchable) {
    state.model.isSearchable = isSearchable;
  }
};
