import tableGetters from "../../../templates/table/getters";
import { isNil } from "lodash";

export default {
  // eslint-disable-next-line no-unused-vars
  siblingHeaders: (state, getters, rootState) => {
    let websites = Object.values(rootState.article.website);

    let localedWebsites = [];
    websites.forEach(website =>
      localedWebsites.push({
        id: website.id,
        text: website.host,
        locales: website.locales.map(
          locale => rootState.article.locale[locale].code
        ),
        sortable: false
      })
    );

    let sortedWebsites = [];
    const orders = ["nl_be", "nl_nl", "fr_be", "fr_fr", "en_gb", "de_de"];

    orders.forEach(order => {
      localedWebsites.forEach(website => {
        if (website.locales.includes(order)) {
          sortedWebsites.push(website);
        }
      });
    });

    return sortedWebsites;
  },
  siblingForWebsite: state => (clusterId, websiteId) => {
    const siblings = state.articleCluster[clusterId].articleSiblings.map(
      sibling => state.articleSibling[sibling]
    );

    const sibling = siblings
      .filter(sibling => sibling.websiteId === websiteId)
      .find(Boolean);
    if (isNil(sibling)) {
      debugger;
    }
    return sibling;
  },
  siblingArticle: state => siblingId =>
    state.article[state.articleSibling[siblingId].article],
  articleSiblingRemark: state => siblingId =>
    state.articleSibling[siblingId].remark,
  articleSiblingColor: state => siblingId =>
    state.articleSibling[siblingId].color,
  ...tableGetters
};
