import tableMutations from "../../../templates/table/mutations";
import { SET_ITEMS } from "../../../templates/table/mutation-types";
import { isNil } from "lodash";
import { setEntities } from "../../../templates/entities";
export default {
  setArticleArticleRemark(state, { remark, articleSiblingId }) {
    state.articleSibling[articleSiblingId].remark = remark;
  },
  setArticleArticleColor(state, { color, articleSiblingId }) {
    state.articleSibling[articleSiblingId].color = color;
  },
  ...tableMutations,
  [SET_ITEMS](state, { ids, entities, meta }) {
    if (!isNil(meta)) {
      Object.assign(state.pagination, {
        totalItems: meta.total,
        page: meta.current_page,
        rowsPerPage: parseInt(meta.per_page)
      });
    } else {
      // @TODO find good implementation for store that do client side filtering/pagination
      Object.assign(state.pagination, {
        totalItems: ids.length,
        page: 1,
        rowsPerPage: 20
      });
    }
    if (!isNil(entities.entity)) {
      setEntities(state, entities, true);
    }
    state.ids = ids;
  }
};
