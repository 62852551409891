import api from "@/api";
import { FETCH as DISPATCH_FETCH } from "@/store/templates/table/action-types";
import tableActions from "@/store/templates/table/actions";

const fetchCall = api.article.sibling.fetchAll;

export default {
  ...tableActions,
  async [DISPATCH_FETCH](context) {
    // OVERRIDE THE DEFAULT
    const websiteClusterId =
      context.rootState.article.website[context.rootState.article.model.website]
        .cluster;
    return await tableActions.fetch(context, {
      fetchCall: fetchCall.bind(null, websiteClusterId)
    });
  }
};
